<template>
  <b-modal
    id="modal-gudang"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
        <b-row>
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div
              style="cursor: pointer;"
              @click="directPage"
            >
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Informasi Pengguna
            </h1>
          </b-col>
          <b-col
            cols="3"
            class="text-right"
          >
            <b-button
              class="px-3"
              @click="editUuid == null ? createItem() : updateItem()"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col
            cols="12"
            md="6"
          >
            <b-row>
              <b-col
                cols="12"
                class="text-center mb-2"
              >
                <UploadPhoto
                  v-if="!photo_url"
                  :label-for="'upload_photo'"
                  :style-name="'width:195px;height:140px;margin: 0 auto;'"
                  :photo-url-parent="photo_url"
                  @photo="getPhoto"
                />
                <div v-else>
                  <div
                    class="box__upload--photo mx-auto"
                    :style="`background-image: url(${photo_url});`"
                    @click="photo_url = ''"
                  >
                    <b-avatar
                      class="floating__close--button"
                    >
                      <feather-icon
                        class="text-danger"
                        icon="XIcon"
                      />
                    </b-avatar>
                  </div>
                </div>
              </b-col>
              <!-- Nama -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-nama">Nama <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    id="v-nama"
                    v-model="formPayload.name"
                    type="text"
                    placeholder="Nama"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <!-- Jabatan -->
              <!-- <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-jabatan">Jabatan <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    id="v-jabatan"
                    v-model="formPayload.jabatan"
                    type="text"
                    placeholder="Jabatan"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col> -->
              <!-- Cabang -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-cabang">Cabang <span class="text-danger">*</span></label>
                <b-form-group>
                  <!-- <b-form-input
                    id="v-cabang"
                    v-model="formPayload.branch_uuid"
                    type="text"
                    placeholder="Jabatan"
                    class="custom__input"
                  /> -->
                  <v-select
                    id="v-cabang"
                    v-model="formPayload.branch_uuid"
                    label="name"
                    :options="optionsCabang"
                    placeholder="Pilih Cabang"
                    @search="onSearchCabang"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-nama-toko">Jabatan <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    id="v-nama-toko"
                    v-model="formPayload.jabatan"
                    type="text"
                    placeholder="Jabatan"
                    :class="Array.isArray(messages.jabatan) ? 'error-validation' : ''"
                    class="custom__input"
                  />
                  <small
                    v-if="messages.jabatan && Array.isArray(messages.jabatan) && messages.jabatan.length > 0"
                    class="text-error fw-bold-500 size12"
                  >{{ messages.jabatan.join(', ') }}</small>
                </b-form-group>
              </b-col> -->
              <!-- Handphone -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-phone">No. Handphone <span class="text-danger">*</span></label>
                <b-form-group>
                  <div class="d-flex align-items-center" style="gap: 0.5rem">
                    <div class="w-50">
                      <b-form-select class="custom__input" v-model="selectedCountryCode">
                        <b-form-select-option :value="item.dial_code" v-for="(item, index) in countryCodes"
                          :key="index">{{ item.dial_code }} ({{ item.name }})</b-form-select-option>
                      </b-form-select>
                    </div>
                    <b-form-input
                      id="v-phone"
                      v-model="formPayload.phone"
                      type="number"
                      placeholder="No. Handphone"
                      class="custom__input"
                      @input="removeLeadingZero"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <!-- Email -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-email">Email</label>
                <b-form-group>
                  <b-form-input
                    id="v-email"
                    v-model="formPayload.email"
                    type="email"
                    placeholder="Email"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <!-- Password -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-password">Password</label>
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="v-c-password"
                    v-model="formPayload.password"
                    :type="passwordFieldType"
                    placeholder="Password"
                    class="form-control-merge custom__input"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <!-- Konfirmasi Password -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-c-password">Konfirmasi Password</label>
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="v-c-password"
                    v-model="formPayload.password_confirmation"
                    :type="passwordFieldTypeConfirm"
                    placeholder="Konfirmasi Password"
                    class="form-control-merge custom__input"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconConfirm"
                      @click="togglePasswordVisibilityConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <!-- User Roles -->
              <b-col
                cols="12"
                md="12"
                class="custom__form--input mb-2"
              >
                <div class="form-group">
                  <div class="row">
                    <div
                      class="col-12"
                    >
                    <label>Role <span class="text-danger">*</span></label>
                    </div>
                    <div
                      v-for="role in roles" 
                      :key="role.id"
                      class="col-3 mb-0"
                    >
                      <div class="form-group">
                        <b-form-checkbox
                          v-model="formPayload.roles"
                          class="text-capitalize text-dark mt-25"
                          :value="role.id"
                          @change="handleRoleSelection"
                        >
                          {{ role.name }}
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <!-- User Permissions -->
              <b-col
                cols="12"
                md="12"
              >
                <div class="form-group">
                  <div
                    v-for="permissionItem, key in permissions"
                    :key="key"
                    class="row mt-1"
                  >
                    <div
                      class="col-12"
                    >
                      <label class="text-dark size14 mb-1"><strong>{{ permissionItem.group_name }}</strong></label>:
                    </div>
                    <div
                      v-for="permission in permissionItem.permission_list"
                      :key="permission.id"
                      class="col-4 mb-0"
                    >
                      <div class="form-group">
                        <b-form-checkbox
                          :id="'permission_checkbox_' + permission.id"
                          v-model="formPayload.permissions"
                          class="text-capitalize text-dark"
                          :value="permission.id"
                        >
                          {{ permission.name }}
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable vue/require-default-prop */
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BInputGroup, BInputGroupAppend, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import { togglePasswordVisibility, togglePasswordVisibilityConfirm } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import UploadPhoto from '@/components/UploadPhoto.vue'

import { country_codes } from '@/libs/country-codes'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BAvatar,
    BContainer,
    UploadPhoto,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, BFormSelectOption
  },
  mixins: [togglePasswordVisibility, togglePasswordVisibilityConfirm],
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    createItem: {
      type: Function,
    },
    handlePhoneNumberInput: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: Number,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    logo: {
      type: String,
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldTypeConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    // filteredPermissions() {
    //   if (!this.formPayload.permissions || !Array.isArray(this.formPayload.permissions)) {
    //     return [];
    //   }

    //   return this.permissions.filter(permissionItem => {
    //     return permissionItem.permission_list.some(permission => {
    //       return this.formPayload.roles.some(roleId => {
    //         const role = this.roles.find(role => role.id === roleId);
    //         if (role && role.permissions) {
    //           return role.permissions.some(rolePermission => {
    //             return rolePermission === permission.id;
    //           });
    //         }
    //         return [];
    //       });
    //     });
    //   });
    // },
  },
  data() {
    return {
      formPayload: {
        name: '',
        description: '',
        logo: '',
        jabatan: '',
        permissions: [],
        roles: [],
      },
      countryCodes: country_codes,
      selectedCountryCode: '+62',
      photo_url: '',
      permissions: [],
      roles: [],
      editUuid: null,
      optionsCabang: [],
      selectedPermissionRoles: [],
    }
  },
  watch: {
    logo(value) {
      this.photo_url = value
    },
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        // const payload = {
        //   phone: this.selectedCountryCode + value.phone,
        //   ...value
        // }
        this.$emit('getPayload', value)
        this.$emit('getPhoneCode', this.selectedCountryCode)
      },
      deep: true,
    },
    // 'formPayload.roles': {
    //   handler(value) {
    //     if (Array.isArray(value)) {
    //       const rolePermissionsMap = {};

    //       this.roles.forEach(role => {
    //         rolePermissionsMap[role.id] = role.permissions || [];
    //       });

    //       const role = value.map(e => {
    //         return rolePermissionsMap[e];
    //       });
    //       this.selectedPermissionRoles = role.flat();
    //     }
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.getRoles()
    this.getPermissions()
    this.getCabang()
  },
  destroyed() {
    this.formPayload = {
      name: '',
      description: '',
      logo: '',
      roles: [],
    }
    this.photo_url = ''
    this.permissions = []
    this.editUuid = null
  },
  methods: {
    removeLeadingZero() {
      if (this.formPayload.phone !== null) {
        let value = this.formPayload.phone.toString();
        if (value.startsWith('0') && value.length > 1) {
          this.formPayload.phone = parseInt(value, 10);
        }
      }
    },
    onSearchCabang(search, loading) {
      if (search.length) {
        loading(true)
        this.searchCabang(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.optionsCabang = []
          this.getCabang()
          loading(false)
        }, 500)
      }
    },
    searchCabang: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('cabang/getData', {
        uuid: '',
        params: {
          search,
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.optionsCabang = []
          response.data.data.data.forEach(item => {
            vm.optionsCabang.push(item)
          })
          loading(false)
        })
    }, 300),
    async getCabang() {
      this.$store.dispatch('cabang/getData', {
        uuid: '',
        params: '',
      }).then(result => {
        this.optionsCabang = result.data.data.data
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getPhoto(value) {
      this.formPayload.logo = value.photo
    },
    getRoles() {
      this.$http.get('/users/role')
        .then(result => {
          this.roles = result.data.data
        }).catch(err => {
          console.log(err)
        })
    },
    getPermissions() {
      this.$http.get('/users/permission')
        .then(result => {
          this.permissions = result.data.data
        }).catch(err => {
          console.log(err)
        })
    },
    directPage() {
      this.cleanUpForm()
      this.$bvModal.hide('modal-gudang')
    },
    handleRoleSelection() {
      const selectedRoles = this.formPayload.roles || [];
      const rolePermissionsMap = {};

      this.roles.forEach(role => {
        rolePermissionsMap[role.id] = role.permissions || [];
      });

      const selectedPermissions = selectedRoles.reduce((permissions, roleId) => {
        const rolePermission = rolePermissionsMap[roleId] || [];
        return permissions.concat(rolePermission);
      }, []);

      const uniquePermissions = [...new Set(selectedPermissions)];

      this.formPayload.permissions = uniquePermissions;
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.box__upload--photo {

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EFF1F5;
  border: 1px dashed #7A7F94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 140px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
